import React from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Icon from '../components/Icon';
import Subfooter from '../components/Subfooter';
import Hero from '../components/Hero';
import imgAboutBackground from '../images/img-home-about-bg.png';
import imgProfileBackground from '../images/img-home-feature-career-bg.png';
import '../styles/pages/_home.scss';

const IndexPage = () => (
  <Layout title="Home">
    <Hero />
    <Section className="pt-0 pb-1.5">
      <Container fullWidth>
        <Callout to="/students" className="callout mb-1">
          See how an engineering solution seeker responded to a global crisis
        </Callout>
        <Row>
          <Column size={6}>
            <div
              className="cards__container"
              onClick={() => navigate('/educators/video-topic-series')}
            >
              <Image
                filename="img-home-for-educators.jpg"
                className="cards__bg-img"
              />
              <div className="cards__text pl-2 pr-2 pb-2">
                <h3 className="color-white mb-0">For Educators</h3>
                <p>
                  Connect the infinite potential of engineering to students’
                  everyday lives with engaging instructional resources focusing
                  on fundamental engineering topic areas.
                  <Icon
                    name="arrowright"
                    className=" callout__icon cards__arrow"
                  />
                </p>
              </div>
            </div>
          </Column>
          <Column size={6}>
            <div
              className="cards__container"
              onClick={() => navigate('/students')}
            >
              <Image
                filename="img-home-for-students.jpg"
                className="cards__bg-img"
              />
              <div className="cards__text pl-1 pr-1 pb-2">
                <h3 className="color-white mb-0">For Students</h3>
                <p>
                  Show students the amazing places engineering can take them
                  with interactive resources that spotlight exciting careers and
                  real-world pros working in industries of all kinds.
                  <Icon
                    name="arrowright"
                    className=" callout__icon cards__arrow"
                  />
                </p>
              </div>
            </div>
          </Column>
        </Row>
        <div className="cards__container" onClick={() => navigate('/engineer')}>
          <Image
            filename="img-home-think-engineer.jpg"
            className="cards__bg-img-full-width"
          />
          <div className="cards__text-full-width pl-1 pr-1 pb-2">
            <h3 className="color-white mb-0">
              Learn to Think Like an Engineer
            </h3>
            <p>
              Give students the blueprints to use engineering in their own lives
              using Engineering Habits of Mind, a powerful visual tool to
              empower both teachers and learners.
              <Icon name="arrowright" className=" callout__icon cards__arrow" />
            </p>
          </div>
        </div>
      </Container>
    </Section>
    <Section className="pt-0 pb-0">
      <Container fullWidth>
        <div
          style={{ backgroundImage: `url('${imgAboutBackground}')` }}
          className="about about__bg-img pb-0"
        >
          {/* About our educational initiatives */}
          <Row className="about__overlay">
            <Column size={4}>
              <Image filename="img-home-about.png" className="mt-2 mb-2 ml-2" />
            </Column>
            <Column size={8} className="about__text pl-2 pb-2">
              <h2>About Our Educational Initiatives</h2>
              <p>
                The field of engineering is constantly evolving according to
                what’s going on in the world. ASME educational initiatives are
                designed to give students, educators, and communities a doorway
                into the cutting-edge of engineering through uniquely empowering
                learning opportunities.
              </p>
              <Link to="/about" className="h4 mt-0 mb-1">
                Learn More
              </Link>
            </Column>
          </Row>
        </div>
      </Container>
      <Container fullWidth>
        <div
          style={{
            backgroundImage: `url(${imgProfileBackground})`,
          }}
          className="profile profile__bg-img pt-0"
        >
          {/* Supplier quality launch leader */}
          <Row className="profile__overlay">
            <Column size={8} className="profile__text">
              <h3>Supplier Quality Launch Leader, GM</h3>
              <p className="mb-0">
                Find out how engineering can be used for the greater good with
                Alex from GM. After COVID-19 caused widespread closures of
                manufacturing plants and parts suppliers, Alex and team pivoted
                from their normal duties to help produce ventilators.
              </p>
              <Link
                to="/career-profiles/supplier-quality-launch-leader/"
                className="pt-1"
              >
                Learn more about this career
              </Link>
            </Column>
            <Column size={4}>
              <Image
                filename="img-career-supplier-quality-leader.png"
                className="profile__img"
              />
            </Column>
          </Row>
        </div>
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter />
    </Section>
  </Layout>
);

export default IndexPage;
